import { NgFor } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
  standalone:true,
  imports:[NgFor]
})
export class TermsAndConditionsComponent {
  articles = [
    {
      title: "Article 1 : Objet",
      content: "Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site tenders 360 (ci-après dénommé « le site »)."
    },
    {
      title: "Article 2 : Mentions légales",
      content: `L’édition du site tenders 360 est assurée par la société ALPHA WEB dont le siège social est localisé au 5 rue Selmane El Farissi Sidi Bel Abbès 22000 Algérie.
      <br>Email: <a href="mailto:info@tenders-360.com">info@tenders-360.com</a>
      <br>Tel: +213 7 78 39 56 29 / +213 5 53 58 77 43
      <br>L’hébergeur du site tenders-360.com est la société LIGNE WEB SERVICES sise au 10, RUE DE PENTHIEVRE 75008 PARIS.`
    },
    {
      title: "Article 3 : Accès au site",
      content: `Le site tenders-360.com permet d’accéder aux services suivants :
      <br>Abonnement à l’application tenders360.
      <br>Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.
      <br>Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.`
    },
    {
      title: "Article 4 : Collecte des données",
      content: `Pour la création du compte de l’Utilisateur sur l’application, la collecte des informations au moment de l’inscription sur l’application mobile tenders 360 est nécessaire et obligatoire. La collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.`
    },
    {
      title: "Article 5 : Propriété intellectuelle",
      content: `Les marques, logos ainsi que les contenus du site tenders 360 (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.
      <br>La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.`
    },
    {
      title: "Article 6 : Responsabilité",
      content: `Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.
      <br>Les informations diffusées sur le site tenders 360 sont présentées à titre purement informatif et sont sans valeur contractuelle. En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. Il en est de même pour l’utilisation et l’interprétation des informations communiquées sur la plateforme.`
    },
    {
      title: "Article 7 : Liens hypertextes",
      content: `Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.`
    },
    {
      title: "Article 8 : Cookies",
      content: `Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut survenir.
      <br>Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de l’Utilisateur. Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site. Ces fichiers ne comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.
      <br>L’information présente dans les cookies est utilisée pour améliorer les performances de navigation sur le site tenders-360.com
      <br>En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du logiciel de navigation.`
    },
    {
      title: "Article 9 : Publication par l’Utilisateur",
      content: `Le site tenders 360 permet aux membres de publier des commentaires.
      <br>Dans ses publications, le membre est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en vigueur.
      <br>Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.
      <br>Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé. Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.
      <br>L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes. Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.
      <br>La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.`
    },
    {
      title: "Article 10 : Durée du contrat",
      content: `Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.`
    },
    {
      title: "Article 11 : Droit applicable et juridiction compétente",
      content: `Le présent contrat est soumis à la législation algérienne. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux Algériens compétents pour régler le contentieux.`
    }
  ];
}
