<footer class="px-[25px] bg-primary dark:bg-[#1b1d2a]">
  <div class="flex justify-between flex-wrap py-[22px] gap-x-[30px] gap-y-[15px] items-center max-md:flex-col">
      <div class="flex items-center gap-[4px] text-[14px] font-medium max-md:text-center text-white dark:text-white/60">© Tenders-360  {{ currentYear }} <a class="" href="#"> All rights reserved</a></div>
      <div class="justify-end md:justify-center items-center flex gap-[15px]">
          <a href="https://app.tenders360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-gray-950 dark:hover:text-white/[.87]">Conditions d'utilisation</a>

          <a href="https://app.tenders360.com/politiquedeconfidentialité"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-gray-950 dark:hover:text-white/[.87]">Politique de Confidentialité </a>

          <a href="https://app.tenders360.com/clausedenon-responsabilité"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-gray-950 dark:hover:text-white/[.87]">Clause de Non-Responsabilité</a>

          <a href="https://app.tenders360.com/suppressiondecompte"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-gray-950 dark:hover:text-white/[.87]">Suppression de compte</a>

          <!-- <a href="https://app.tenders360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Qui nous sommes</a>
          <a href="https://app.tenders360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Contactez-nous</a> -->
      </div>
  </div>
</footer>
