// privacy-policy.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  template: `
    <div class="privacy-policy-container dark:text-gray-300">
      <h2 class="policy-title dark:text-gray-300">Politique de Confidentialité</h2>

      <div class="policy-content">
        <h3 class="dark:text-gray-300">Introduction</h3>
        <p>
          Tenders 360 (« nous », « notre » ou « nos ») s'engage à protéger la confidentialité de nos utilisateurs (« vous » ou « vos »).
          Cette Politique de Confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles
          lorsque vous utilisez notre site web <a href="http://app.tenders360.com" target="_blank">app.tenders360.com</a> et notre application mobile Tenders 360.
        </p>

        <h3 class="dark:text-gray-300">1. Informations que nous collectons</h3>
        <p>Nous collectons différents types d'informations dans le cadre de l'exploitation de nos services, y compris :</p>
        <ul>
          <li>Informations que vous nous fournissez : Lorsque vous créez un compte, vous vous inscrivez à notre essai gratuit, ou vous nous contactez pour des questions, nous pouvons collecter des informations telles que votre nom, adresse e-mail, numéro de téléphone et informations de paiement.</li>
          <li>Informations collectées automatiquement : Nous collectons automatiquement des informations sur votre appareil et l'utilisation de nos services, y compris les adresses IP, les identifiants de l'appareil, les types de navigateur, les pages consultées, et les dates et heures de visite.</li>
          <li>Informations de tiers : Nous pouvons recevoir des informations vous concernant de la part de tiers, par exemple si vous utilisez un service tiers pour vous connecter à notre application.</li>
        </ul>

        <h3 class="dark:text-gray-300">2. Utilisation de vos informations</h3>
        <p>Nous utilisons vos informations pour :</p>
        <ul>
          <li>Fournir, exploiter, maintenir et améliorer nos services.</li>
          <li>Gérer votre compte et fournir un support client.</li>
          <li>Envoyer des notifications et des mises à jour concernant notre service.</li>
          <li>Analyser l'utilisation de nos services pour améliorer leur qualité.</li>
          <li>Se conformer aux obligations légales et réglementaires.</li>
        </ul>

        <h3 class="dark:text-gray-300">3. Partage de vos informations</h3>
        <p>Nous pouvons partager vos informations avec :</p>
        <ul>
          <li>Prestataires de services : Nous travaillons avec des prestataires de services qui traitent des informations en notre nom pour nous aider à exploiter nos services.</li>
          <li>Obligations légales : Nous pouvons divulguer vos informations si la loi l'exige ou si nous croyons en toute bonne foi que cette action est nécessaire pour se conformer à une obligation légale.</li>
        </ul>

        <h3 class="dark:text-gray-300">4. Protection de vos informations</h3>
        <p>Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos informations contre la perte, l'utilisation abusive et l'accès non autorisé.</p>

        <h3 class="dark:text-gray-300">5. Vos droits</h3>
        <p>Vous avez le droit d'accéder, de corriger, de supprimer ou de limiter l'utilisation de vos informations personnelles. Pour exercer ces droits, veuillez nous contacter à <a href="mailto:info@tenders-360.com">info&#64;tenders-360.com</a>.</p>

        <h3 class="dark:text-gray-300">6. Modifications de cette politique</h3>
        <p>Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle politique sur notre site et, si les changements sont significatifs, nous vous en informerons par e-mail.</p>

        <h3 class="dark:text-gray-300">7. Nous contacter</h3>
        <p>Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité, veuillez nous contacter à :</p>
        <ul>
          <li>Par e-mail : <a href="mailto:info@tenders-360.com">info&#64;tenders-360.com</a></li>
          <li>Par téléphone : 05 53 58 77 43</li>
        </ul>
      </div>
    </div>
  `,
  styles: [`
    .privacy-policy-container {
      font-family: Arial, sans-serif;
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
    }
    .policy-title {
      text-align: center;
      font-size: 46px;
      margin-bottom: 20px;
    }
    .policy-content {
      font-size: 16px;
    }
    h3 {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 20px;
    }
    a {
      color: #0066cc;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  `]
})
export class PrivacyPolicyComponent {}
