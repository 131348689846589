// disclaimer.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  template: `
    <div class="disclaimer-container dark:text-gray-300">
      <h2 class="dark:text-gray-300 disclaimer-title">Clause de Non-Responsabilité</h2>
      <div class="disclaimer-content">
        <p class="important-notice"><strong>Avis Important :</strong></p>
        <p>Cette application est un service privé et n'est pas affiliée, approuvée ou liée à aucune entité gouvernementale. Les informations disponibles via cette application sont fournies à titre d'information générale et ne doivent pas être interprétées comme des conseils ou informations émanant d'une source officielle.</p>
        <p class="liability-limitation"><strong>Limitation de Responsabilité :</strong></p>
        <p>Tenders 360 ne garantit pas l'exactitude ou la fiabilité des informations fournies par cette application. L'utilisation de cette application est à votre entière discrétion. Tenders 360 n'assume aucune responsabilité pour les conséquences découlant de son utilisation.</p>
      </div>
    </div>
  `,
  standalone: true,
  styles: [`
    .disclaimer-container {
      font-family: Arial, sans-serif;
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
    }
    .disclaimer-title {
      text-align: center;
      font-size: 46px;
      margin-bottom: 20px;
    }
    .disclaimer-content {
      font-size: 16px;
    }
    .important-notice, .liability-limitation {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  `]
})
export class DisclaimerComponent {}
