export const fieldItems: {
  label: string;
  value: string;
}[] = [
  { label: 'Agricole', value: 'agricole' },
  { label: 'Alimentation', value: 'alimentation' },
  { label: 'Architecture', value: 'architecture' },
  { label: 'Assurance', value: 'assurance' },
  { label: 'Banque', value: 'banque' },
  { label: 'Chimie', value: 'chimie' },
  { label: 'Communication', value: 'communcation' },
  { label: 'Comptabilite', value: 'accountancy' },
  { label: 'Culture', value: 'culture' },
  { label: 'Digital', value: 'digital' },
  { label: 'Droit', value: 'droit' },
  { label: 'Economie', value: 'économie' },
  { label: 'Edition', value: 'édition' },
  { label: 'Enseignement', value: 'éducation' },
  { label: 'Environnement', value: 'environnement' },
  { label: 'Finance', value: 'finance' },
  { label: 'Gestion', value: 'gestion' },
  { label: 'Hotellerie', value: 'hotellerie' },
  { label: 'Immobilier', value: 'immobilier' },
  { label: 'Industrie', value: 'industrie' },
  { label: 'Ingénierie', value: 'ingénierie' },
  { label: 'Logistique', value: 'logistique' },
  { label: 'Mécanique', value: 'mécanique' },
  { label: 'Medias', value: 'medias' },
];

export const sectors: {
  id: any;
  item: string;
  arabicName?: string;
}[] = [
  { id: 1, item: 'Aéronautique', arabicName: 'طيران' },
  {
    id: 2,
    item: 'Agriculture, élevage, forêt et pêche',
    arabicName: 'الزراعة والثروة الحيوانية والغابات وصيد الأسماك',
  },
  {
    id: 3,
    item: 'Agro-alimentaire et alimentation',
    arabicName: 'أغذية زراعية وطعام',
  },
  { id: 4, item: 'Ameublement et mobilier', arabicName: 'التأثيث و الاثاث' },
  { id: 5, item: 'Architecture et urbanisme', arabicName: 'العمارة والعمران' },
  { id: 6, item: 'Assurance et banque', arabicName: 'التأمين والمصارف' },
  {
    id: 7,
    item: 'Bâtiment et génie civil',
    arabicName: 'البناء والهندسة المدنية',
  },
  {
    id: 8,
    item: 'Chimie et pétrochimie',
    arabicName: 'الكيماويات والبتروكيماويات',
  },
  {
    id: 9,
    item: 'Électricité bâtiment et éclairage publique',
    arabicName: 'كهرباء المباني والإنارة العامة',
  },
  { id: 47, item: 'Electroménager', arabicName: 'أجهزة كهرومنزلية' },
  {
    id: 10,
    item: 'Énergie et services pétroliers',
    arabicName: 'خدمات الطاقة والنفط',
  },
  {
    id: 11,
    item: 'Équipements industriels, outillage et pièces détachées',
    arabicName: 'المعدات الصناعية والأدوات وقطع الغيار',
  },
  {
    id: 48,
    item: 'Equipements pour les collectivités et équipements de ville',
    arabicName: 'معدات للمجتمعات ومعدات المدينة',
  },
  {
    id: 12,
    item: 'Équipements scientifiques et laboratoires',
    arabicName: 'المعدات والمختبرات العلمية',
  },
  { id: 13, item: 'Études et consulting', arabicName: 'دراسات واستشارات' },
  {
    id: 14,
    item: 'Formation et certification',
    arabicName: 'التدريب والشهادة',
  },
  {
    id: 15,
    item: 'Froid, climatisation, chauffage et plomberie',
    arabicName: 'تبريد وتكييف وتدفئة وسباكة',
  },
  { id: 16, item: 'Habillement et textile', arabicName: 'الملابس والمنسوجات' },
  {
    id: 17,
    item: 'Hydraulique, assainissement traitement des eaux',
    arabicName: 'المكونات الهيدروليكية ، ومعالجة مياه الصرف الصحي',
  },
  { id: 18, item: 'Hygiène et environnement', arabicName: 'النظافة والبيئة' },
  { id: 19, item: 'Immobilier', arabicName: 'العقارات' },
  {
    id: 20,
    item: 'Impression, édition et communication',
    arabicName: 'الطباعة والنشر والاتصال',
  },
  {
    id: 21,
    item: 'Industrie de la cellulose, papier, carton et emballage',
    arabicName: 'صناعة السليلوز والورق والكرتون والتغليف',
  },
  {
    id: 22,
    item: 'Industrie électroniques et matériel audiovisuel',
    arabicName: 'صناعة الإلكترونيات والسمعية والبصرية',
  },
  { id: 23, item: 'Industrie manufacturière', arabicName: 'الصناعة التحويلية' },
  {
    id: 24,
    item: 'Industrie sidérurgique, métallurgique et mécanique',
    arabicName: 'صناعة الحديد والصلب والمعدنية والميكانيكية',
  },
  {
    id: 25,
    item: 'Industries électriques et électrotechnique',
    arabicName: 'الصناعات الكهروتقنية والكهربائية',
  },
  {
    id: 26,
    item: 'Informatique et bureautique',
    arabicName: 'الكمبيوتر والمكتب',
  },
  {
    id: 27,
    item: 'Maintenance des équipements industriels',
    arabicName: 'صيانة المعدات الصناعية',
  },
  { id: 28, item: 'Matériaux de construction', arabicName: 'مواد بناء' },
  { id: 29, item: 'Médical et paramédical', arabicName: 'طبي وشبه طبي' },
  {
    id: 30,
    item: 'Mines, cimenterie, agrégats et granulats',
    arabicName: 'المناجم وأعمال الأسمنت والركام والركام',
  },
  { id: 31, item: 'Pharmacie', arabicName: 'صيدلية' },
  {
    id: 46,
    item: 'Pneumatiques et pièces de rechange auto',
    arabicName: 'اطارات وقطع غيار سيارات',
  },
  {
    id: 32,
    item: 'Port, aéroport et transit',
    arabicName: 'الميناء والمطار والعبور',
  },
  {
    id: 33,
    item: 'PVC, aluminium, vitrerie et menuiserie bois',
    arabicName: 'والألمنيوم والتزجيج والنجارة الخشبية  PVC ',
  },
  { id: 45, item: 'Quincaillerie', arabicName: 'خردوات' },
  {
    id: 34,
    item: 'Restauration et hôtellerie',
    arabicName: 'المطاعم و الضيافة',
  },
  {
    id: 35,
    item: 'Sablage, peinture et travaux plâtre',
    arabicName: 'أعمال السفع الرملي والطلاء والجص',
  },
  {
    id: 36,
    item: 'Sécurité, gardiennage et vidéosurveillance',
    arabicName: 'أمن وحراسة ومراقبة بالفيديو',
  },
  { id: 37, item: 'Services', arabicName: 'خدمات' },
  {
    id: 38,
    item: 'Services de comptabilité et commissariat aux comptes',
    arabicName: 'خدمات المحاسبة و مدقق حسابات',
  },
  { id: 39, item: 'Sport et loisirs', arabicName: 'رياضة وهوايات' },
  { id: 40, item: 'Télécommunication', arabicName: 'اتصالات' },
  { id: 41, item: 'Tourisme et voyage', arabicName: 'سياحة وسفر' },
  { id: 42, item: 'Transports', arabicName: 'النقل' },
  { id: 43, item: 'Travaux publics', arabicName: 'أشغال عمومية' },
  { id: 44, item: 'Vente aux enchères', arabicName: 'بيع المزاد' },
];

export const wilaya: {
  id: any;
  code?: string;
  name: string;
  arabicName?: string;
}[] = [
  { id: 1, code: '1', name: 'Adrar', arabicName: 'أدرار' },
  { id: 2, code: '2', name: 'Chlef', arabicName: 'الشلف' },
  { id: 3, code: '3', name: 'Laghouat', arabicName: 'الأغواط' },
  { id: 4, code: '4', name: 'Oum El Bouaghi', arabicName: 'أم البواقي' },
  { id: 5, code: '5', name: 'Batna', arabicName: 'باتنة' },
  { id: 6, code: '6', name: 'Béjaïa', arabicName: 'بجاية' },
  { id: 7, code: '7', name: 'Biskra', arabicName: 'بسكرة' },
  { id: 8, code: '8', name: 'Béchar', arabicName: 'بشار' },
  { id: 9, code: '9', name: 'Blida', arabicName: 'البليدة' },
  { id: 10, code: '10', name: 'Bouira', arabicName: 'البويرة' },
  { id: 11, code: '11', name: 'Tamanrasset', arabicName: 'تمنراست' },
  { id: 12, code: '12', name: 'Tébessa', arabicName: 'تبسة' },
  { id: 13, code: '13', name: 'Tlemcen', arabicName: 'تلمسان' },
  { id: 14, code: '14', name: 'Tiaret', arabicName: 'تيارت' },
  { id: 15, code: '15', name: 'Tizi Ouzou', arabicName: 'تيزي وزو' },
  { id: 16, code: '16', name: 'Alger', arabicName: 'الجزائر' },
  { id: 17, code: '17', name: 'Djelfa', arabicName: 'الجلفة' },
  { id: 18, code: '18', name: 'Jijel', arabicName: 'جيجل' },
  { id: 19, code: '19', name: 'Sétif', arabicName: 'سطيف' },
  { id: 20, code: '20', name: 'Saïda', arabicName: 'سعيدة' },
  { id: 21, code: '21', name: 'Skikda', arabicName: 'سكيكدة' },
  { id: 22, code: '22', name: 'Sidi Bel Abbès', arabicName: 'سيدي بلعباس' },
  { id: 23, code: '23', name: 'Annaba', arabicName: 'عنابة' },
  { id: 24, code: '24', name: 'Guelma', arabicName: 'قالمة' },
  { id: 25, code: '25', name: 'Constantine', arabicName: 'قسنطينة' },
  { id: 26, code: '26', name: 'Médéa', arabicName: 'المدية' },
  { id: 27, code: '27', name: 'Mostaganem', arabicName: 'مستغانم' },
  { id: 28, code: '28', name: "M'Sila", arabicName: 'المسيلة' },
  { id: 29, code: '29', name: 'Mascara', arabicName: 'معسكر' },
  { id: 30, code: '30', name: 'Ouargla', arabicName: 'ورقلة' },
  { id: 31, code: '31', name: 'Oran', arabicName: 'وهران' },
  { id: 32, code: '32', name: 'El Bayadh', arabicName: 'البيض' },
  { id: 33, code: '33', name: 'Illizi', arabicName: 'إليزي' },
  {
    id: 34,
    code: '34',
    name: 'Bordj Bou Arreridj',
    arabicName: 'برج بوعريريج',
  },
  { id: 35, code: '35', name: 'Boumerdès', arabicName: 'بومرداس' },
  { id: 36, code: '36', name: 'El Tarf', arabicName: 'الطارف' },
  { id: 37, code: '37', name: 'Tindouf', arabicName: 'تندوف' },
  { id: 38, code: '38', name: 'Tissemsilt', arabicName: 'تيسمسيلت' },
  { id: 39, code: '39', name: 'El Oued', arabicName: 'الوادي' },
  { id: 40, code: '40', name: 'Khenchela', arabicName: 'خنشلة' },
  { id: 41, code: '41', name: 'Souk Ahras', arabicName: 'سوق أهراس' },
  { id: 42, code: '42', name: 'Tipaza', arabicName: 'تيبازة' },
  { id: 43, code: '43', name: 'Mila', arabicName: 'ميلة' },
  { id: 44, code: '44', name: 'Aïn Defla', arabicName: 'عين الدفلى' },
  { id: 45, code: '45', name: 'Naâma', arabicName: 'النعامة' },
  { id: 46, code: '46', name: 'Aïn Témouchent', arabicName: 'عين تموشنت' },
  { id: 47, code: '47', name: 'Ghardaïa', arabicName: 'غرداية' },
  { id: 48, code: '48', name: 'Relizane', arabicName: 'غليزان' },
  { id: 49, code: '49', name: 'Timimoune', arabicName: 'تيميمون' },
  {
    id: 50,
    code: '50',
    name: 'Bordj Badji Mokhtar',
    arabicName: 'برج باجي مختار',
  },
  { id: 51, code: '51', name: 'Ouled Djellal', arabicName: 'أولاد جلال' },
  { id: 52, code: '52', name: 'Béni Abbès', arabicName: 'بني عباس' },
  { id: 53, code: '53', name: 'In Salah', arabicName: 'عين صالح' },
  { id: 54, code: '54', name: 'In Guezzam', arabicName: 'عين قزام' },
  { id: 55, code: '55', name: 'Touggourt', arabicName: 'تقرت' },
  { id: 56, code: '56', name: 'Djanet', arabicName: 'جانت' },
  { id: 57, code: '57', name: "El M'Ghair", arabicName: 'المغير' },
  { id: 58, code: '58', name: 'El Meniaa', arabicName: 'المنيعة' },
];
