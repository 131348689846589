import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { authGuard } from './core/auth/guards/auth.guard';
import { DisclaimerComponent } from './components/disclaimer-component/disclaimer.component';
import { PrivacyPolicyComponent } from './components/privacy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { AccountDeletionComponent } from './components/account-deletion/account-deletion.component';
import { DownloadApkComponent } from './components/download-apk/download-apk.component';

const appRoutes: Routes = [
  { path: '',
   redirectTo: 'home',
    pathMatch: 'full'
  },
  {
      path: '',
      redirectTo: '/authentication/sign-in',
      pathMatch: 'full',
  },
  {
      path: '',
      component: CommonLayoutComponent,
      children: [
        {
          path: 'home',
          loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        },
        {
          path:'clausedenon-responsabilité',
          component:DisclaimerComponent
        },
        {
          path:'politiquedeconfidentialité',
          component:PrivacyPolicyComponent
        },
        {
          path:'conditionsdutilisation',
          component:TermsAndConditionsComponent
        },
        {
          path:'suppressiondecompte',
          component:AccountDeletionComponent
        },

        {
          path:'downloadapk',
          component:DownloadApkComponent
        },

        {
          path: 'favorites',
          loadChildren: () => import('./favorite/favorite.module').then(m => m.FavoriteModule),

          canMatch: [authGuard],
        },
        {
          path: 'recap',
          loadChildren: () => import('./recap/recap.module').then(m => m.RecapModule),
          canMatch: [authGuard],
        },
        {
          path: 'profile',
          loadChildren: () => import('./profile/profile.routes'),
          canMatch: [authGuard],
        },
      ]
  },
  {
      path: '',
      component: FullLayoutComponent,
      children: [
        {
          path: 'authentication',
          loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
      ]
  }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
