import { FavoriteModel } from '../models/favorite.model';
import { SearchModel } from '../models/search-model';
import { tenderOffre } from '../models/tender-offre';
export class Constants {
  static ACCESS_TOKEN = 'accessToken';
  static EMAIL = 'email';
  /* routes  names */
  static LOGIN_ROUTE = 'sign-in';
  static LOGOUT_ROUTE = 'sign-out';
  static REGISTER_ROUTE = 'sign-up';
  static FORGOT_ROUTE = 'forgot-password';
  static RESET_PASSWORD_ROUTE = 'reset-password';
  static SEARCH_ROUTE = 'search';
  static HOME_ROUTE = 'home';
  static FAVORITE_ROUTE = 'favorites';
  static RECAPE_ROUTE = 'recap';
  static REDIRECTION_ROUTE = 'redirection';
  static REDIRECTION_AUTH_ROUTE = 'redirection-auth';
  static ACCOUNT_ACTIVATION_ROUTE = 'account-activation';
  static PROFILE_ROUTE = 'profile';
  static ABOUT_US_ROUTE = 'about-us';
  static CONTACT_US_ROUTE = 'contact-us';
  static PRIVACY_POLICY_ROUTE = 'privacy-policy';
  static POLITICS_ROUTE = 'politics';
  static NOT_FOUND_ROUTE = 'not-found';

  /** helpers */
  static DATE_FORMAT_FOR_BACK_SENDER = 'yyyy-MM-dd';
  static MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD-MM-YYYY', // this is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD-MM-YYYY', // this is how your date will get displayed on the Input
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  /* filters names */
  static SEARCH_FILTER = 'search';
  static WILAYAS_FILTER = 'wilayas';
  static SECTORS_FILTER = 'sectors';

  static PUB_DATE_FILTER = 'equalToDatePublication';
  static ECH_DATE_FILTER = 'equalTodateEcheance';


  /* snackbar messages */
  static SNACKBAR_SUCCESS = 'success-message-snackbar';
  static SNACKBAR_ERROR = 'error-snackbar';
  static SNACKBAR_INFO = 'info-message-snackbar';

  static HOME_PAGE_OFFERS_KEY = 'home-page-offers-key';
  static SEARCH_PAGE_OFFERS_KEY = 'search-page-offers-key';
  static HOME_FAVORITE_JOBS_KEY = 'favorite-jobs-key';
  static SEARCH_FAVORITE_JOBS_KEY = 'search-favorite-jobs-key';
  static FAVORITE_FAVORITE_JOBS_KEY = 'search-favorite-jobs-key';
  static JOB_CLICK_KEY = 'job-click-key';

  static USER_INFO = 'user-info';
  /* actions */
  static LOGIN_ACTION = '[Login] Login';
  static LOGIN_LOADING_ACTION = '[Login] Login loading';
  static LOGIN_SUCCESS_ACTION = '[Login] Login Success';
  static LOGIN_FAILURE_ACTION = '[Login] Login Failure';
  static LOGOUT_ACTION = '[Logout] Logout';
  static CHANGE_PASSWORD_ACTION = '[profile] change password';

  static REGISTER_CREDENTIALS_ACTIONS = '[sign-up] register credentials';
  static METHOD_CHOSEN_ACTIONS = '[sign-up] method chosen';
  static USER_INFOS_ACTIONS = '[sign-up] user infos action';
  static REGISTER_LOADING_ACTION = '[sign-up] sign-up loading';
  static REGISTER_FAILURE_ACTION = '[sign-up] sign-up Failure';
  static SET_IS_FIRST_LOGIN_ACTION =
    '[account-finalization] set first login false action';


  /* method to encode query params */
  static encode({ valueToEncode }: { valueToEncode: any }): string {
    return btoa(valueToEncode);
  }
  /* method to decode query params */
  static decode({ valueToDecode }: { valueToDecode: string }): any {
    return atob(valueToDecode);
  }

  /* remove accents using normalize */
  static normalizeWord({ word }: { word: string }): string {
    /**
     * NFD unicode normalization form that decomposes
     * accented characters into
     * a base character and one or more combining characters.
     */

    /**
     *  \u0300-\u036f represents the range of accents
     * for example \u0300 - Combining Grave Accent
     */

    return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }


  /* encode query params */
  static encodeQueryParams({
    queryParams,
  }: {
    queryParams: Map<string, any>;
  }): string {
    const keys = queryParams.keys();
    const encodedParams = Array.from(keys).map(
      (key) =>
        `${encodeURIComponent(key)}=${this.encode({ valueToEncode: queryParams.get(key) })}`,
    );
    return encodedParams.join('&');
  }
}
