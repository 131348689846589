import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { Constants } from '../data/constants';
import { StorageUtilsService } from '../storage-utils/storage-utils.service';
/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const httpInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const _StorageUtilsService = inject(StorageUtilsService);
  const _platformId = inject(PLATFORM_ID);
  let modified = request;

  if (_StorageUtilsService.getAccessToken()) {
    modified = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + _StorageUtilsService.getAccessToken(),
      },
    });
  }
  return next(modified).pipe(
    retry(0),
    catchError((error: HttpErrorResponse) => {
      if (!(error.error instanceof ErrorEvent)) {
        switch (error.status) {
          case 400:
            break;

          case 401:

            break;
          case 403:
            if (error.error && error.error.errorKey) {
         
            } else {
            }
            break;
          case 404:
            if (error.error && error.error.errorKey) {
            
            } else {
            }
            break;

          case 405:
            if (error.error && error.error.errorKey) {
            } else {
            }
            break;

          case 500:
            break;
          case 502:
            break;
          case 504:
            if (error.error && error.error.errorKey) {
            } else {
            }
            break;
          default:
            break;
        }
      }
      return throwError(error);
    }),
  );
};

