import { Component } from '@angular/core';

@Component({
  selector: 'app-account-deletion',
  template: `
    <div class="dark:text-gray-300" data-mesh-id="comp-lykmbw045inlineContent-gridContainer" data-testid="mesh-container-content">
  <div id="comp-lykmbw052" class="dark:text-gray-300 HcOXKn c9GqVL QxJLC3 lq2cno comp-lykmbw052 wixui-rich-text" data-testid="richTextElement">
    <h2 class="dark:text-gray-300 font_2 wixui-rich-text__text" style="text-align:center; font-size:46px;">
      Suppression de compte Tenders 360
    </h2>
  </div>

  <div id="comp-lykmbw061" class="dark:text-gray-300 HcOXKn c9GqVL QxJLC3 lq2cno comp-lykmbw061 wixui-rich-text" data-testid="richTextElement">
    <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
      Pour demander la suppression de votre compte et des données associées, veuillez envoyer un courriel à&nbsp;
    </p>
    <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
      <span class="wixui-rich-text__text">
        <span style="text-decoration:underline;" class="wixui-rich-text__text">
          <a href="mailto:info@tenders-360.com" target="_self" class="wixui-rich-text__text">info&#64;tenders-360.com&nbsp;</a>
        </span>
      </span>
      avec les informations suivantes :
    </p>

    <ul class="font_8 wixui-rich-text__text" style="font-size:16px;">
      <li class="wixui-rich-text__text">
        <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
          Nom d'utilisateur ou adresse e-mail associée à votre compte
        </p>
      </li>
      <li class="wixui-rich-text__text">
        <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
          Une demande explicite de suppression de votre compte
        </p>
      </li>
    </ul>

    <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
      Après la suppression de votre compte, les données suivantes seront supprimées définitivement :
    </p>

    <ul class="font_8 wixui-rich-text__text" style="font-size:16px;">
      <li class="wixui-rich-text__text">
        <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
          Vos informations personnelles (nom, adresse, etc.)
        </p>
      </li>
      <li class="wixui-rich-text__text">
        <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
          Votre historique d'activité dans l'application
        </p>
      </li>
      <li class="wixui-rich-text__text">
        <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
          Toutes les données liées à votre compte
        </p>
      </li>
    </ul>

    <p class="font_8 wixui-rich-text__text" style="font-size:16px;">
      Veuillez noter que certaines données anonymisées peuvent être conservées à des fins statistiques et d'amélioration du service, conformément à notre politique de confidentialité.
    </p>
  </div>
</div>

  `,
  styles: [`
   .font_2 {
  font-size: 46px;
  text-align: center;
}

.font_8 {
  font-size: 16px;
}

  `]
})
export class AccountDeletionComponent {}
