import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../data/constants';
import { AuthenticationResponse } from '../../models/authentication-response';
import { StorageUtilsService } from '../../storage-utils/storage-utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _httpClient: HttpClient,
    private _StorageUtilsService: StorageUtilsService,
    private _router: Router,
  ) {}

  /* login password method */
  signIn({
    loginCred,
  }: {
    loginCred: any;
  }): Observable<AuthenticationResponse> {
    const url: string = `${environment.ENDPOINTS.LOGIN_URL}`;
    return this._httpClient.post<any>(url, loginCred);
  }

  /* register password method */
  signUp({ signUpCred }: { signUpCred: any }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.REGISTER_URL}`;
    return this._httpClient.post<any>(url, signUpCred);
  }

  /* forgot password method */
  forgotPassword({ email }: { email: string }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.FORGOT_PASSWORD_URL}`;
    return this._httpClient.post<any>(url, email);
  }

  resetPassword({
    resetDto,
  }: {
    resetDto: {
      newPassword: string;
      confirm: string;
      key: string;
    };
  }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.RESET_PASSWORD_URL}`;
    return this._httpClient.post<any>(url, resetDto);
  }

  /*  logout method */
  signOut() {
    this._StorageUtilsService.removeAccessToken();
    this._router.navigate([Constants.HOME_ROUTE]);
  }

  checkIsLoggedIn(): Observable<boolean | null> {
    const accessToken = this._StorageUtilsService.getAccessToken();

    // check if user has access token
    if (
      accessToken &&
      accessToken !== '' &&
      accessToken !== 'undefined' &&
      accessToken !== 'null'
    ) {
      return of(true);
    } else {
      return of(false);
    }
  }
}
