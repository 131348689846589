import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { Constants } from '../../data/constants';
import { AuthService } from '../service/auth.service';

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService
    .checkIsLoggedIn()
    .pipe(
      map((isLogged: boolean | null) =>
        isLogged ? true : router.createUrlTree(['authentication',Constants.LOGIN_ROUTE]),
      ),
    );
};
