<div class="terms-container dark:text-gray-300">
  <h2 class="dark:text-gray-300 title">Conditions générales d’utilisation<br>du site tenders-360.com</h2>

  <div class="intro">
    <p>
      Nos services sont régis par les dispositions du présent document dénommé « conditions générales d'utilisation», ainsi toute consultation du site, toute inscription, tout abonnement ou réabonnement souscrit depuis le site ou l’application mobile tenders360 implique l’adhésion entière et sans réserve de l’utilisateur aux présentes conditions générales d'utilisation.
    </p>
  </div>

  <div class="articles">
    <div *ngFor="let article of articles" class="article">
      <h3 class="dark:text-gray-300">{{ article.title }}</h3>
      <p [innerHTML]="article.content"></p>
    </div>
  </div>
</div>
