export interface Filters {
  label?: string;
  value?: string;
  type?: FiltersTypes;
  cardClass?: string;
  selectedValues?: any;
}
export enum FiltersTypes {
  WilayasFilter,
  SectorsFilter,
  SearchFilter,
  SalaryFilter,
}

export interface FiltersDto {
  page?: any;
  size?: any;
  sort?: string;
  equalToDatePublication?: string;
  equalTodateEcheance?: string;
  title?: string;
  search?: string;
  organismSearch?: string;
  sectorIds?: any;
  wilayaIds?: any;
  organismeId?: any;
  tenderTypeAttachmentIds?: any;
}

export interface LabelValueModel {
  label: string;
  value: string;
}
