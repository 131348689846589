import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Constants } from '../../core/data/constants';

@Injectable({
  providedIn: 'root',
})
export class StorageUtilsService {
  private storage: any = localStorage;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = {
        data: {},
        getItem(key: string): string | null {
          return this.data[key] || null;
        },
        setItem(key: string, value: string): void {
          this.data[key] = value;
        },
        removeItem(key: string): void {
          delete this.data[key];
        },
      };
    }
  }

  setAccessToken({ token }: { token: string }): void {
    this.storage.setItem(Constants.ACCESS_TOKEN, token);
  }
  getAccessToken(): string {
    return this.storage.getItem(Constants.ACCESS_TOKEN) ?? '';
  }
  removeAccessToken(): void {
    this.storage.removeItem(Constants.ACCESS_TOKEN);
  }
}
